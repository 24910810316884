$blue-bg : #161C46;
$color-orange : #FAA946;
$color-orange-hover : #ffa435;
$color-white : #fff;
$color-blue : #005596;
$color-blue-hover : #014b83;
$color-blue-light : #2E3A7A;



$text-color: #222222;
$color-dot : #D9D9D9;
$light-pink-color : #FFEEDF;
$gray-color : #666;

