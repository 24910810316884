@mixin container-common-property {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 0 40px;
}


@mixin heading_2 {
  color: $text-color;
  font-size: 42px;
  font-weight: 900;
  line-height: 46px;
}

@mixin h6 {

  color: $color-orange !important;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 100%;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;

}

@mixin common-padding {
  padding: 80px 20px 40px !important;
}

@mixin common-padding-for-40 {
  padding: 40px 20px 40px !important;
}

@mixin common-top-padding-for-40 {
  padding-top: 40px !important;
  padding-bottom: 0 !important;
}
@mixin common-bottom-padding-for-40 {
  padding-top: 40px !important;
}

@mixin common-margin-for-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
@mixin common-margin-for-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}