//community page

.hero-section {
  .wp-block-cover__inner-container {
    max-width: unset !important;
  }
}

figure {
  margin: 0 0;

  figcaption {
    background: black;
    color: white;
    padding: 6px 0.2rem;
    font-size: 12px;
  }
}
.community {
  &__para {
    padding: 80px 0 40px;
    color: var(--GDF-Neutral---Dark-Grey, #222);
    font-family: Brandon Grotesque;
    font-size: 24px;
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
  }

  &__title {
    padding: 80px 0 40px !important;
    font-size: 42px !important;
    max-width: 1080px;
    margin: 0 auto !important;
    width: 90%;
  }

  &__card {
    max-width: 252px !important;
    width: 100%;

    h2 {
      font-size: 32px !important;
      line-height: 25px !important;
      min-height: 50px;
    }

    &__img {
      height: 252px !important;
      max-height: 252px !important;
    }

    .wp-block-heading {
      color: var(--GDF-Neutral---Dark-Grey, #222) !important;
      text-align: center;
      font-family: Brandon Grotesque !important;
      font-size: 25px !important;
      font-weight: 500 !important;
      line-height: 25px !important;
    }

    .wp-block-button {
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }

  &__grid {
    display: flex !important;
    max-width: 1080px;
    margin: 0 auto;
    align-items: flex-start;
    gap: 24px;
    justify-content: center;
    padding-bottom: 56px;
    width: 90%;
    flex-wrap: wrap;
  }
}

.financial-section-body {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

/* Mission & Pillars css started */
.missionImageBlock,
.missionhd,
.missionpanel {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

.missionImageBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.missionImageBlock figure {
  margin-bottom: 0;
}

.missionImageBlock img {
  width: 100%;
}

.missionhd {
  font-size: 24px !important;
  margin-bottom: 40px !important;
}

.missionpanel {
  display: flex;
  gap: 24px;
  padding: 40px 0 80px;
  justify-content: center;

  figure {
    margin: 0 0 !important;
  }
}

.missionblock {
  background: #2e3a7a;
  color: #fff;
  width: 25%;
  padding: 40px 20px;
  text-align: center;
  max-width: 258px;
}

.missionblock h2 {
  font-size: 1.75rem !important;
  color: #fff !important;
  line-height: 22px;
}

.missionblock p {
  font-size: 24px;
  line-height: 33.6px;
}

.captioned-images {
  // max-width: 550px;
  // height: 500px;
  position: relative;
  figure {
    position: relative;
    width: 100%;
    margin: 0 0;

    figcaption {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: black;
      color: white;
      padding: 0.2rem;
      font-size: 18px;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

/* Mission & Pillars css end */
/* Leadership css started */
.leadershipbanner {
  margin-bottom: 60px;
}

.leadershipbanner h2 {
  color: #fff !important;
  padding: 0 40px !important;
}

.teampanel {
  max-width: 1320px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  gap: 24px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.teamblock {
  width: 30%;
  max-width: 252px;
}

.teamblock img {
  max-width: 100%;
}

.teamblock h6 {
  font-size: 32px !important;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 36px !important;
  color: #222 !important;
  text-transform: none !important;
  margin: 0 I !important;
  font-family: Brandon Grotesque bold !important;
}

.teamblock p {
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  text-align: center;
  padding: 0 20px !important;
  line-height: 1.4 !important;
}

/* Leadership css end */

//who we are

a,
button,
btn {
  transition: all ease-in-out 0.25s;
}

header {
  &.wp-block-cover {
    h4 {
      color: #fff;
      font-size: 4rem !important;
      font-weight: 600 !important;
      font-family: Brandon Grotesque !important;
      text-align: center;
    }

    p.has-text-align-center {
      text-align: center;
      font-weight: 100;

      strong {
        mark {
          color: #fff;
        }
      }
    }

    .wp-block-button {
      &.btn-hero-banner {
        display: flex;
        justify-content: center;
        margin: 25px 0 20px;

        .wp-element-button {
          background: #161c46;
          color: #fff;
          text-decoration: none;
          padding: 15px 25px;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1.2px;

          &:hover {
            background: #111638;
          }
        }
      }
    }
  }

  .wp-block-cover {
    &__inner-container {
      max-width: unset !important;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.left-image-text-section {
  display: flex;
  max-width: 1080px;
  margin: 0px auto;
  flex-direction: column;
}

.left-image-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  margin: 50px auto !important;
  max-width: 1080px;
  gap: 24px;

  figure {
    &.wp-block-media-text__media {
      img {
        width: 100%;
      }
    }
  }
}

 
//financial page
.financial-section-body {
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  h1 {
    margin-top: 32px;
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
    margin: 0 0 20px;
  }

  ul {
    margin-top: 0px;
    margin-left: 32px;
    padding-bottom: 20px;
  }

  li {
    list-style-type: disc;
    text-decoration: underline;
    font-size: 22px !important;
  }

  mark {
    color: black !important;
  }
}

.mentorship__page .mentorship__text__block,
#mentorship__video__title {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

#mentorship__video__title {
  padding-top: 80px !important;
}

#mentorship__text__block__titile {
  margin-top: 40px;
}

// mentorship
.mentorship {
  &__para {
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
    padding-top: 80px;
  }

  &__page {
    color: var(--GDF-Neutral---Dark-Grey, #222) !important;
    font-family: Brandon Grotesque;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 420 !important;
    line-height: 140%;
  }

  &__text__wrapper {
    padding-bottom: 40px;
  }

  &__text__img {
    img {
      max-width: 528px !important;
      max-height: 352px !important;
    }
  }

  &__video {
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;

    &__card {
      width: 344px;
      height: 257px;
      padding-top: 16px;
      margin-bottom: 140px !important;
      text-align: center;

      &__wrapper {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 20px;
      }
    }

    #mentorship__video__title strong {
      color: var(--GDF-Neutral---Dark-Grey, #222);

      /* GDF H2 */
      font-family: Brandon Grotesque;
      font-size: 42px;
      font-style: normal;
      font-weight: bold;
      line-height: 46px;
      /* 109.524% */
    }
  }

  &__text__para {
    h5 {
      color: var(--GDF-Neutral---Dark-Grey, #222);
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }

    h6 {
      color: var(--GDF-Neutral---Dark-Grey, #222) !important;
      text-align: center;
      font-family: Brandon Grotesque !important;
      font-size: 24px !important;
      font-style: normal !important;
      font-weight: 420 !important;
      line-height: 140%;
      padding-top: 16px !important;
    }
  }
}

// mentorship ended

/**Sponsorship css started **/
.sponsortext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.sponsortext a {
  color: #005596;
  text-decoration: underline;
}

/**Sponsorship css end **/

/**marthon css started **/
.marthontext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.marthontext a {
  color: #005596;
  text-decoration: underline;
}

.marthonpanel {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.marthonpanel img {
  max-width: 100%;
}

/**marthon css end **/

/**donors css started **/
.donortext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.donortext a {
  color: #005596;
  text-decoration: underline;
}

.donorspanel {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.donorspanel img {
  max-width: 100%;
}

/**donors css end **/
/* Grants css started */
.godcontainer {
  max-width: 1080px;
  margin: 0 auto;
}

.grantspanel {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.yellowgrantblock {
  max-width: 100%;
  background: #ffeedf;
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 80px 0;
  align-items: center;
  margin-bottom: 40px;
}

.yellowgrantblock .yellowgrant {
  max-width: 528px;
}

.grantsblock {
  padding-top: 80px;
}

.grantsblock p {
  padding-bottom: 20px;
  margin: 0;
}

.grantspanel img {
  max-width: 100%;
}

.grantspanel figure {
  margin: 0;
}

.yellowgrantblock .yellowgrant img {
  max-width: 100%;
}

.grantsblock h1 {
  font-size: 60px;
  font-family: Brandon Grotesque bold !important;
  padding-top: 60px;
  margin-top: 0;
  margin-bottom: 20px;
}

.grantsblock h2 {
  font-size: 42px;
  font-family: Brandon Grotesque bold !important;
  margin: 40px 0;
}

.grantspanel {
  margin-bottom: 80px;
}

.grantshd {
  margin-bottom: 40px !important;
}

.partnershd {
  color: #faa946 !important;
  font-size: 20px !important;
  margin-bottom: 40px !important;
  text-align: center;
}

.partnertext {
  font-size: 32px !important;
  font-family: Brandon Grotesque bold !important;
  color: #222 !important;
  text-align: center;
  margin-bottom: 20px;
}

.partnername {
  font-size: 24px !important;
  text-align: center;
  margin-bottom: 80px;
}

/* Grants css end */

// getinvolved

.getinvoled {
  &__text {
    color: var(--GDF-Neutral---Dark-Grey, #222);
    font-family: Brandon Grotesque !important;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 46px;

    h6 {
      color: var(--GDF-Neutral---Dark-Grey, #222) !important;
      font-family: Brandon Grotesque !important;
      font-style: normal;
      font-weight: 420 !important;
      line-height: 140%;
      /* 33.6px */
      text-align: left !important;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 252px !important;
    min-height: 410px !important;
    text-align: center;
    color: white;
    padding-bottom: 40px !important;
    padding-top: 30px !important;
    .wp-block-button {
      display: flex;
      justify-content: center !important;
    }

    .wp-block-button__link {
      display: flex;
      height: 48px !important;
      width: 126px !important;
      align-items: center;
      justify-content: center;
      color: black;
      text-decoration: none !important;
    }

    h2 {
      color: white !important;
      color: var(--GDF-Neutral---White, #fff);
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 32px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 36px !important;
      min-height: 80px;
    }
    img {
      width: 212px !important;
      height: 180px !important;
    }
  }

  &__columns {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .wp-block-column {
      padding: 0 1rem !important;
      margin: 1rem 0 !important;
    }
  }

  &__wrappper {
    .missionblock {
      width: 100% !important;
    }
  }
}

// our work css start

.our-work-title {
  margin-bottom: 20px !important;
  font-size: 42px !important;
  font-family: Brandon Grotesque bold !important;
}

.our-work-sub-title {
  margin-top: 2rem !important;
  font-size: 36px !important;
  font-family: Brandon Grotesque bold !important;
}

.our-work-list {
  li {
    list-style-type: disc !important;
  }
}

.our-work-sub-group {
  //display: flex !important;
  flex-direction: column !important;
  max-width: 1080px;
  margin: 0 auto;
}

.our-work-title-and-text {
  max-width: 1080px;
  margin: 0 auto;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 80px;

  p {
    font-size: 24px;
    line-height: 33px;
  }
}

// our work css end

.primary-button {
  padding: 1rem;
  width: min-content;
  background-color: #faa946;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  a {
    text-decoration: none !important;
    color: #222222 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: Brandon Grotesque bold !important;
  }
}

.our-work-list {
  margin-bottom: 20px;
}

.season-of-giving-content {
  margin-top: 2rem;
  // margin-right: 6rem !important;
}

.season-of-giving-content-img {
  overflow: hidden;
  margin-left: 6rem !important;
  margin-right: 6rem !important;

  img {
    height: auto;
  }
}

.season-of-giving-content-media {
  display: flex;
  flex-direction: row;
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.bannerTitle {
  color: #fff !important;
  font-size: 4.8rem !important;
  font-weight: 600 !important;
  font-family: Brandon Grotesque !important;
  text-align: center;
}

.community_hospital .community__grid h2 {
  min-height: 78px;
}

.hero-section.lg-text h4 {
  text-align: center;
}

.mission-pillar-card-section h2 {
  min-height: auto;
}

.god-heading.ex-dir .bod-row .bod-col {
  max-width: 260px !important;
  width: 100%;
}

.donate__page .left-section .text-group {
  align-items: center !important;
}

.blog-details .story-banner img {
  height: 351px !important;
  width: 100%;
  object-fit: cover !important;
}

.see-all-stories {
  .title {
    color: #666;
    font-weight: 600;
    grid-column: 1/-1;
  }
}
.carousle-card {
  position: relative;
  .caption {
    background: black;
    color: white;
    padding: 6px 0.2rem;
    font-size: 13px;
    position: absolute;
    bottom: 23px;
    width: 100%;
    &:empty {
      display: none;
    }
  }
}

.new-hero-banner {
  .wp-block-cover__inner-container {
    max-width: 100%;
  }
  h4 {
    &.new-hero-section-text {
      font-size: 64px !important;
    }
  }
}

////////////////////*****media query arranged****///////////////////////////////

@media screen and (max-width: 1366px) {
  .wp-block-cover.alignfull.hero-section h4.wp-block-heading {
    text-align: center !important;
    padding: 0 !important;
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    text-align: left;
    max-width: 35%;
    margin: 0 !important;
  }

  .hero-section.ticket-donation-banner {
    min-height: 425px !important;
  }
}

@media screen and (max-width: 1300px) {
  .new-hero-banner {
    img {
      min-height: 350px !important;
    }
  }
}

@media screen and (max-width: 1250px) {
  .mentorship__video__card__wrapper {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 344px);
    gap: 16px;
    justify-content: center;
  }
  .community__grid {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .financial-section-body {
    padding-top: 40px !important;
  }

  .community__para {
    padding: 40px 0 40px !important;
  }

  .grantsblock {
    padding: 40px 20px !important;
  }

  .hero-section.ticket-donation-banner {
    min-height: 280px !important;
  }

  .getinvoled__columns {
    .getinvoled__card {
      padding: 0 !important;
    }
  }

  header {
    &.wp-block-cover {
      h4 {
        font-size: 3rem !important;
      }

      &__inner-container {
        max-width: 980px;
        padding: 0 30px;
      }

      p.has-text-align-center strong mark {
        color: #fff;
        font-size: 16px;
      }
    }

    .wp-block-cover {
      &__inner-container {
        padding: 0 30px;
      }

      .wp-block-button {
        &.btn-hero-banner {
          .wp-element-button {
            padding: 12px 15px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .left-image-text {
    max-width: 980px;
    padding: 0 30px;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 1150px) {
  .new-hero-banner {
    min-height: 270px !important;
    h4.new-hero-section-text {
      font-size: 64px !important;
      text-align: center;
      max-width: 35%;
    }
  }

  header.wp-block-cover h4 {
    max-width: 66%;
  }

  .our-work-title-and-text {
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    img {
      height: auto;
      width: 40vw !important;
      margin-left: 2rem !important;
    }
  }

  .our-work-sub-group {
    padding: 30px 20px !important;
  }

  .community__grid {
    width: 95%;
  }

  .hero-section .wp-block-cover__inner-container {
    max-width: 37% !important;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    margin: 0 auto;
  }

  .wp-block-cover h4 {
    text-align: center;
    width: 100% !important;
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 40px !important;
    text-align: left;
    max-width: 80%;
    margin: 0 !important;
  }
}

@media screen and (max-width: 1100px) {
  .blog-details-container {
    .blog-details {
      .story-banner {
        img {
          padding: 20px;
        }
      }
      h2 {
        padding: 0 20px !important;
      }
    }
  }

  .category-container {
    padding: 0 20px;
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 30px !important;
    text-align: left;
    max-width: 35%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .getinvoled__text {
    padding: 0 20px;
  }
  header.wp-block-cover h4 {
    text-align: left !important;
    max-width: 100%;
    width: 100%;
  }

  .community__grid__parent,
  .community_hospital {
    width: 90%;
    margin: 0 auto;
  }

  .community__grid .wp-block-column {
    width: 25%;
  }

  .sponsorship-section {
    padding: 30px 30px 50px !important;
  }

  .community__card__img img {
    height: auto;
    max-width: 100%;
  }

  .community__para {
    padding: 40px 0;
  }

  .community__title {
    padding: 30px 0 !important;
    font-size: 30px !important;
  }

  .community__card .wp-block-heading {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1090px) {
  .mentorship__page .mentorship__text__block,
  #mentorship__video__title {
    width: 100% !important;
    padding: 0 20px !important;
  }

  .marthonpanel,
  .marthontext {
    width: 90%;
  }
  .donorspanel,
  .donortext {
    width: 90%;
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    max-width: 76%;
  }

  .media-with-left-caption-section .media-with-left-caption-images .caption-image,
  .media-with-left-caption-section .media-with-right-caption-images .caption-image {
    max-width: 528px;
    height: auto;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1037px) {
  .getinvoled__columns {
    .getinvoled__card {
      max-width: 350px !important;
      padding: 0 !important;
      padding: 1rem !important;
    }

    .wp-block-column {
      display: flex;
      justify-content: center;
    }

    justify-content: center;
    grid-template-columns: 1fr 1fr !important;
  }

  header .wp-block-cover__inner-container {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1024px) {
  .grantspanel {
    flex-wrap: wrap;
  }

  .grantspanel img {
    max-width: 100%;
  }

  .yellowgrantblock {
    flex-wrap: wrap;
  }

  .yellowgrantblock .yellowgrant {
    width: 100%;
  }

  .yellowgrantblock .yellowgrant img {
    max-width: 100%;
  }

  .landing-page-images {
    height: auto !important;
    width: 100%;
    display: grid;
    // grid-template-columns: 384px 640px;

    .left-section {
      // height: 320px !important;
      height: auto !important;
      width: 100%;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      width: 100%;
      height: auto !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .mentorship {
    &__page {
      color: var(--GDF-Neutral---Dark-Grey, #222) !important;
      font-family: Brandon Grotesque;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 420 !important;
      line-height: 140%;
    }

    &__text__img {
      .wp-block-media-text {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }

  .mentorship__text__img img {
    max-width: 100% !important;
    max-height: 352px !important;
    width: unset !important;
    min-width: unset !important;
  }

  .mentorship__video__card__wrapper {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 344px);
    gap: 16px;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  p {
    font-size: 18px;
    line-height: normal;
  }
}

@media screen and (max-width: 980px) {
  .community__card {
    max-width: 100% !important;
  }

  .community__card__img {
    height: auto !important;
    text-align: center;
  }

  .hero-section {
    height: auto !important;
    // min-height: 252px !important;
    width: 100%;

    img {
      object-fit: contain;
      // height: auto !important;
    }

    width: 100%;
    .wp-block-cover__inner-container {
      h4 {
        width: unset !important;
        font-size: 36px !important;
      }
    }
  }
}

@media screen and (max-width: 979px) {
  .hero-section {
    height: auto !important;
    width: 100%;

    img {
      object-fit: contain;
      margin-bottom: 40px !important;
      padding-bottom: 20px !important;
    }

    width: 100%;
    .wp-block-cover__inner-container {
      h4 {
        width: unset !important;
        font-size: 36px !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wp-block-cover.alignfull.hero-section h4.wp-block-heading {
    font-size: 26px !important;
  }

  .getinvoled__columns {
    .getinvoled__card {
      padding: 0 !important;
      padding: 1rem !important;
    }

    .wp-block-column {
      display: flex;
      justify-content: center;
    }

    justify-content: center;
    grid-template-columns: 1fr !important;
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    max-width: 80% !important;
  }
}

@media screen and (max-width: 767px) {
  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 22px !important;
    text-align: left;
    max-width: 35%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .media-with-left-caption-section .media-with-left-caption-images,
  .media-with-left-caption-section .media-with-right-caption-images {
    grid-template-columns: 1fr;
  }

  .media-with-left-caption-section .media-with-left-caption-images .caption-image,
  .media-with-left-caption-section .media-with-right-caption-images .caption-image {
    max-width: 100%;
    height: auto;
  }

  section.landingPage {
    &.hero-section {
      .carousel-caption {
        max-width: 30% !important;
      }
    }
    h2 {
      font-size: 22px !important;
    }
  }

  .community__title {
    padding: 30px 0 20px !important;
  }

  .donor-advised-section {
    padding: 30px 0 40px;
  }

  .our-work-title-and-text {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    img {
      height: auto !important;
      width: 90vw !important;
      margin-left: 0rem !important;
    }

    .our-work-sub-group {
      padding-left: 2rem !important;
    }
  }

  .season-of-giving-content-media {
    flex-direction: column;
  }

  .landing-page-images {
    height: auto;

    .left-section {
      height: auto !important;

      .text-group {
        padding: 0 20px;
      }
    }

    .right-section {
      height: auto !important;
    }
  }

  .left-image-text {
    grid-template-columns: 1fr;
  }

  header.wp-block-cover {
    p.has-text-align-center {
      text-align: left;
    }
  }

  .landing-page-images {
    height: auto !important;
    width: 100%;
    display: grid;

    .left-section {
      height: auto !important;
      width: 100% !important;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      width: 100% !important;
      height: auto !important;
    }
  }

  .hero-section-with-two-images {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    height: auto;
    background-color: red;

    .wp-block-cover__background,
    .has-background-dim {
      display: none;
    }

    .right-section {
      height: auto;

      figure {
        margin-bottom: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .left-section {
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .hero-textcenter h5 {
    margin-bottom: 20px !important;
    font-size: 18px;
    padding: 20px 20px !important;
  }
}

@media screen and (max-width: 720px) {
  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    max-width: 90% !important;
  }

  .our-work-sub-title {
    margin-top: 0 !important;
    font-size: 28px !important;
    padding-bottom: 15px !important;
  }

  .hero-textcenter-section .hero-textcenter h5 {
    font-size: 18px !important;
    line-height: normal !important ;
  }

  .mission-pillar-card-section .inner-card-container .getinvoled__card {
    min-height: auto !important;
    padding: 20px !important;
  }

  header.wp-block-cover h4 {
    max-width: 60%;
    text-align: left !important;
    width: 100%;
    font-size: 35px !important;
  }

  .donorspanel {
    flex-wrap: wrap;
  }

  .ticket-donate-btn-section {
    .button-row {
      display: grid !important;
      grid-template-columns: 1fr !important;
      max-width: 95% !important;
      margin: 0 auto !important;
    }
  }
  .marthonpanel {
    flex-wrap: wrap;
  }

  .hero-section .wp-block-cover__inner-container {
    max-width: 39% !important;
    position: absolute;
    left: 7px;
  }
}

@media screen and (max-width: 680px) {
  .godcontainer.grantshd.godhead.godhead {
    padding: 0 !important;
  }

  .wp-block-cover.alignfull.hero-section h4.wp-block-heading {
    font-size: 22px !important;
  }

  .grantspanel {
    flex-wrap: wrap;
  }

  .grantspanel img {
    max-width: 100%;
  }

  .yellowgrantblock {
    flex-wrap: wrap;
  }

  .yellowgrantblock .yellowgrant {
    width: 100%;
  }

  .yellowgrantblock .yellowgrant img {
    max-width: 100%;
  }

  .grantsblock h1 {
    font-size: 32px;
    margin-top: 20px;
  }

  .godcontainer {
    max-width: 100%;
    padding: 20px !important;
  }

  .teamblock {
    width: 90%;
    max-width: 90%;
  }

  .teamblock figure {
    text-align: center;
  }

  .community__grid .wp-block-column {
    width: 45%;
  }

  p.bannerTitle {
    font-size: 30px !important;
  }

  .missionpanel {
    flex-wrap: wrap;
  }

  .missionblock {
    width: 45%;
  }

  .missionImageBlock {
    display: flex;
    flex-wrap: wrap;
  }

  .teamblock h6 {
    font-size: 20px !important;
  }

  .getinvoled__columns.two-x-two {
    grid-template-columns: auto !important;
  }
}

@media screen and (max-width: 640px) {
  .hero-section .wp-block-cover__inner-container {
    max-width: 37% !important;
    text-align: center;

    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-page-images {
    .text-group {
      padding: 0 20px !important;
      h2 {
        font-size: 24px !important;
      }

      p {
        font-size: 14px !important;
      }
    }
  }

  .donate__page__para {
    margin-top: 40px !important;
    padding: 0px 20px;
  }

  section.landingPage h2 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 580px) {
  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 18px !important;
    text-align: left;
    max-width: 35%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .wp-block-cover.alignfull.hero-section h4.wp-block-heading {
    font-size: 18px !important;
  }

  .community_hospital .community__grid h2 {
    min-height: unset;
  }

  .hero-textcenter-section {
    margin: 24px 1rem;
  }
}
@media screen and (max-width: 480px) {
  section.landingPage h2 {
    font-size: 16px !important;
  }

  .community__card h2 {
    min-height: unset;
  }

  .wp-block-cover.alignfull.hero-section h4.wp-block-heading {
    font-size: 14px !important;
  }

  .missionpanel {
    flex-wrap: wrap;
  }

  .missionblock {
    width: 100%;
  }

  .hero-section .wp-block-cover__inner-container h4 {
    font-size: 20px !important;
  }

  .ticket-donations-card {
    width: unset;
  }

  .generic-text {
    padding: 1rem !important;
  }

  .ticket-donate-btn-section {
    padding: 15px 20px !important;
    .wp-block-buttons {
      display: grid !important;
    }
  }

  .getinvoled__columns.two-x-two {
    grid-template-columns: auto !important;
  }

  .community__grid .wp-block-column {
    width: 100%;
  }

  .community__grid {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 420px) {
  section.landingPage h2 {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 390px) {
  .landing-page-images {
    height: auto !important;
    width: 100% !important;
    display: grid;

    .left-section {
      height: 125px !important;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      height: auto !important;
    }
  }

  .hero-section {
    height: auto !important;
    min-height: auto !important;
    width: 100%;

    width: 100%;
    .wp-block-cover__inner-container {
      h4 {
        width: unset !important;
        font-size: 12px !important;
        padding-left: 0px !important;
      }
    }
  }

  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 320px) {
  .landing-page-images {
    height: auto !important;

    .left-section {
      height: auto !important;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      height: auto !important;
    }
  }
}

.landingPage.hero-section .carousel-inner .carousel-caption .btn.btn-orange {
  height: 66.667px !important;
  padding: 10.667px 34.667px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 13.333px !important;
  font-size: 21.333px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100% !important;
  background: #faa946 !important;
  color: #222222 !important;
  @media screen and (max-width: 1440px) {
    height: 51.483px !important;
    padding: 8.237px 26.771px !important;
    gap: 10.297px !important;
    font-size: 16.475px !important;
  }
  @media screen and (max-width: 1024px) {
    height: 47.639px !important;
    padding: 7.622px 24.772px !important;
    gap: 9.528px !important;
    font-size: 15.244px !important;
  }
  @media screen and (max-width: 768px) {
    height: 35.729px !important;
    padding: 5.717px 18.579px !important;
    gap: 7.146px !important;
    font-size: 11.433px !important;
  }
}
section.landingPage.hero-section .carousel-indicators button.carousel-dot {
  @media screen and (max-width: 768px) {
    margin: 0 5px !important;
  }
}
